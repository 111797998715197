<template>
  <div>
    <template v-if="!param.vendorFlag">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="height"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :key="tab.key"
            :riskReduce.sync="riskReduce"
            :param="param"
            :height="height"
            @research="research"
          />
        </template>
      </c-tab>
    </template>
    <template v-else>
      <component
        :is="imprComponent"
        :riskReduce.sync="riskReduce"
        :param="param"
        :height="height"
        @research="research"
      />
    </template>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'riskTab',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
        searchRiskReduce: {
          flag: '',
          isSearch: false,
        },
        searchScenario: {
          flag: '',
          isSearch: false,
        },
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      tab: 'impr',
      imprComponent: () => import(`${'@/pages/ram/4m/risk/4mImpr.vue'}`),
      riskReduce: {
        imprs: [],
        riskbooks: [],
      }
    };
  },
  watch: {
    'param.searchRiskReduce.flag'() {
      this.getList();
    },
    'param.planUpdateBtnData.research'() {
      this.getList();
    },
  },
  computed: {
    tabItems() {
      let imprComponent = null;
      let riskComponent = null;
      switch(this.param.ramTechniqueCd) {
        case 'RT00000001':
          imprComponent = () => import(`${'@/pages/ram/hazop/risk/hazopImpr.vue'}`)
          riskComponent = () => import(`${'@/pages/ram/hazop/risk/hazopRiskRegister.vue'}`)
          break // HAZOP
        case 'RT00000005':
          imprComponent = () => import(`${'@/pages/ram/kpsr/risk/kpsrImpr.vue'}`)
          riskComponent = () => import(`${'@/pages/ram/kpsr/risk/kpsrRiskRegister.vue'}`)
          break // K-PSR
        case 'RT00000010':
          imprComponent = () => import(`${'@/pages/ram/checklist/risk/checklistImpr.vue'}`)
          riskComponent = () => import(`${'@/pages/ram/checklist/risk/checklistRiskRegister.vue'}`)
          break // Check-list
        case 'RT00000015':
          imprComponent = () => import(`${'@/pages/ram/jsa/risk/jsaImpr.vue'}`)
          riskComponent = () => import(`${'@/pages/ram/jsa/risk/jsaRiskRegister.vue'}`)
          break // JRA
        case 'RT00000020':
          imprComponent = () => import(`${'@/pages/ram/kras/risk/krasImpr.vue'}`)
          riskComponent = () => import(`${'@/pages/ram/kras/risk/krasRiskRegister.vue'}`)
          break // KRAS
        case 'RT00000025':
          imprComponent = () => import(`${'@/pages/ram/4m/risk/4mImpr.vue'}`)
          riskComponent = () => import(`${'@/pages/ram/4m/risk/4mRiskRegister.vue'}`)
          break // 4M
        case 'RT00000030':
          imprComponent = () => import(`${'@/pages/ram/charm/risk/charmImpr.vue'}`)
          riskComponent = () => import(`${'@/pages/ram/charm/risk/charmRiskRegister.vue'}`)
          break // CHARM
        case 'RT00000035':
          imprComponent = () => import(`${'@/pages/ram/third/risk/thirdImpr.vue'}`)
          riskComponent = () => import(`${'@/pages/ram/third/risk/thirdRiskRegister.vue'}`)
          break // 3단계 판단법
      }
      return [
        { key: uid(), name: 'impr', icon: 'construction', label: 'Punch List', component: imprComponent },
        { key: uid(), name: 'risk', icon: 'report_gmailerrorred', label: 'LBL0001171', component: riskComponent },
      ];
    },
    listUrl() {
      let url = null;
      switch(this.param.ramTechniqueCd) {
        case 'RT00000001':
          url = selectConfig.ram.hazop.riskReduce.list.url;
          break // HAZOP
        case 'RT00000005':
          url = selectConfig.ram.kpsr.riskReduce.list.url;
          break // K-PSR
        case 'RT00000010':
          url = selectConfig.ram.checklist.riskReduce.list.url;
          break // Check-list
        case 'RT00000015':
          url = selectConfig.ram.jsa.riskReduce.list.url;
          break // JRA
        case 'RT00000020':
          url = selectConfig.ram.kras.riskReduce.list.url;
          break // KRAS
        case 'RT00000025':
          url = selectConfig.ram.fm.riskReduce.list.url;
          break // 4M
        case 'RT00000030':
          url = selectConfig.ram.charm.riskReduce.list.url;
          break // CHARM
        case 'RT00000035':
          url = selectConfig.ram.third.riskReduce.list.url;
          break // 3단계 판단법
      }
      return url;
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.$_.extend(this.riskReduce, _result.data);
      },);
    },
    research() {
      this.$emit('emitStep', {
        name: 'research',
        param: {
          place: 'riskReduce',
        }
      })
    },
  }
};
</script>
